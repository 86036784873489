/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdBdncdAAAAAJn9Iaf6G_pMzu6hGhMedR0NZ2-K">
      {element}
    </GoogleReCaptchaProvider>
  )
}
